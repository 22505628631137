/* Varialblen */
:root {
    --color-mandant: var(--color-mandant);
    --color-mandant: #1F487C;
  }
/* global definitions */
body {
    background: #eee;
    font-size: 18px;
    color: #444;
    /* color: #1F487C; */
}
body, input, textarea, button, select {
  font-family: 'Lato', sans-serif;
}
#contentwrapper {
    max-width: 750px;
    margin: 2em auto 0;
    padding: 2em 3em 1.5em 3em;
    background: #fff;
    /* min-height: 600px; */
    display: grid;
}

.legal {
    text-align: center;
    margin-top: 5px;
    padding-bottom: 15px;
    font-size: .7em;
}

.legal a {
    margin: 0 5px;
}

a {
    color: var(--color-mandant);
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
/* table */
table {
    width: 100%;
    font-size: 0.9em;
    border-collapse: collapse;
}
th, td {
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    padding: 0.9em 0.7em;
}
thead th {
    font-size: 0.9em;
    width: fit-content;
}
tbody th {
    text-align: right;
    padding-left: 0.3em;
}
tbody td {
    table-layout: fixed;
}
tbody td input[type="radio"] {
    float: none;
    width: 2.0em;
    height: 2.0em;
}
tbody tr {
    background: #f3f3f3;
    border-bottom: 2px solid #fff;
}

/* form elements */
label {
    display: flow-root;
    padding-bottom: 0.75em;
    padding-top: 0.4em;
}
.pleaseagree label,
label:hover,
.singleanswer label:hover {
    color: var(--color-mandant);
}
.accesskey {
    color: var(--color-mandant);
    opacity: 0.6;
    padding-right: 0.5em;
    font-size: 0.8em;
    float: left;
    margin-top: 0.15em;
}
.accesskey::before {
    content: "[";
}
.accesskey::after {
    content: "]";
}
/* Texteingabe*/
input[type="text"] , input[type="email"], input[type="number"] {
    padding: 0.5em;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 1em;
    width: 50%;
}
input[type="number"] {
    padding: 0.5em;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-size: 1em;
    width: 50%;
}
/* radion Buttons */
input[type="radio"] {
    -moz-appearance: None;
    -webkit-appearance: none;
    width: 2.5em;
    height: 2.5em;
    background: url(./images/radio-01-empty.svg) center center no-repeat;
    background-size: contain;
    border: none;
    outline: none;
    vertical-align: middle;
    margin-left: 0;
    margin-right: 1em;
    padding-left: 0;
    float: left;
}
input[type="radio"]:hover {
    background-image: url(./images/radio-02-hover.svg);
    background-size: contain;
}
input[type="radio"]:checked {
    background-image: url(./images/radio-03-active.svg);
    background-size: contain;
}
.radiocontent.checked {
    color: #fff;
}

.data .label {
    display: block;
    margin-bottom: 0.25em;
}
.data label {
    display: block;
}
.data input[type="text"] {
    display: block;
    margin-top: 0.25em;
}
.data input[type="radio"] {
    margin-top: -0.3em;
}

/* Checkboxen */
input[type="checkbox"] {
    -moz-appearance: None;
    -webkit-appearance: none;
    width: 2.5em;
    height: 2.5em;
    background: url(./images/checkbox-01-empty.svg) center center no-repeat;
    background-size: contain;
    border: none;
    outline: none;
    vertical-align: middle;
    margin-left: 0;
    margin-right: 1em;
    padding-left: 0;
    float: left;
}
input[type="checkbox"]:hover {
    background-image: url(./images/checkbox-02-hover.svg);
    background-size: contain;
}
input[type="checkbox"]:checked {
    background-image: url(./images/checkbox-03-active.svg);
    background-size: contain;
}

/* Textfeld */
textarea {
    border: 1px solid #ddd;
    width: 95.5%;
    padding: 1em;
}

/* Submit Button */
.enter {
    position: absolute;
    margin: 1em;
    color: #ccc;
    font-size: 0.75em;
    font-weight: bold;
    text-transform: uppercase;
}
.submit_container {
    margin: 2em;
    text-align: center;
}
.footer-container {
    display: flex;
    justify-content: flex-end;
}
.footer-container a {
    margin-left: 1rem;
    text-decoration: solid;
}
input[type="button"] {
    display: inline-block;
    margin: 10px;
    padding: 0.5em 1.5em;
    color: #fff;
    background: var(--color-mandant);
    border: none;
    border-radius: 1.5em;
    font-size: 1em;
    text-transform: uppercase;
}
input[type="button"].inactive {
    background: #eee;
}
input[type="button"].active {
    cursor: pointer;
}
input[type="button"].active:hover {
    -webkit-box-shadow: 0 0 5px 2px rgba(0,204,255,1);
    box-shadow: 0 0 5px 2px rgba(0,204,255,1);
}
input[type="button"].active:active {
    -webkit-box-shadow: 0 0 5px 2px rgba(0,204,255,1);
    box-shadow: 0 0 5px 2px rgba(0,204,255,1);
    background: #1f487c;
}



/* header definitions */
header {
    margin: 0;
}
.questionnaire-brand{
    color: #1F487C;
    display: flex;
}
.questionnaire-name {
    /* font-size: 1.4em; */
    font-weight: bold;
    line-height: 0.8;
    display: inline-block;
    margin: 0 0 0.37em 0;
}
.questionnaire-brand img {
    /* max-width: 350px; */
    max-height: 25px;
}
.progress {
    font-size: 0.7em;
    color: #aaa;
    margin-top: 0.5em;
}
div.continue-later {
    float: right;
    cursor: pointer;
    color: #1F487C;
}
.counter {
    white-space: nowrap;
}
.modul-counter::before {
    content: "\00a0 \00b7  \00a0"
}


/* content definitions */
main {
    margin: 3em 0;
}

.questionhint {
    font-size: 0.8em;
    color: #999;
    margin-bottom: 1.5em;
}

/* footer definitions */
footer {
    padding-top: 1em;
    display: flex;
    align-items: flex-end;
    /* align-items: center; */
    color: #aaa;
    margin-left: 2px;
}
footer div {
    width: 50%;
}
.logomandant {
    order: 2;
    font-size: 0.7em;
    text-align: right;
}
.logomandant img {
    max-width: 200px;
    max-height: 70px;
}
.cntbrand {
    font-size: 0.7em;
    order: 1;
}
.cntbrand p {
    margin: 0;
    text-align: left;
}
.cntbrand p.brandheadline {
    font-weight: bold;
    color: #1F487C;
}
.cntbrand p.copyright {
    font-size: 0.9em;
}

/* BEGIN SPECIAL DEFINITIONS FOR PAGES */

/* CMS Textpage */
.maincontent.textpage {
    font-size: 0.9em;
    margin-left: 8%;
    margin-right: 8%;
}
h2 {
    font-size: 1.2em;
}
.maincontent.textpage .icon {
    /* position: absolute;
    margin-left: -60px;*/
    float: left;
    margin-right: 1em;
}
.sc_scale_combi {
    display: flex;
    align-items: stretch;
}
.sc_scale_singleanswers {
    width: 1.0%;
}
.sc_scale_label {
    margin-left: 3.8em;
    background: #f3f3f3;
    padding: 0.8em;
    border-bottom: 2px solid #fff;
    width: 100%;
}

div.sc_scale_label {
    display: flex;
    align-items: center;
}

/* CMS Success-Page / Erfolgsseite  */
.maincontent.success {
    /* color: #1F487C; */
    text-align: center;
}
.maincontent.success h2 {
    /* font-size: 40px;
    line-height: 0.5; */
    font-size: 1.5em;
}
/* Login-Page  */
.maincontent.login {
    /* color: #1F487C; */
    text-align: center;
}
.login .submit_container{
    margin-top: 0.5em;
}

/* single choice horizontal */
.sc_weighting .helperdiv {
    display: none;
}

/* block with added content */
.added_content {
    margin: 1em 0;
}
.added_content * {
    max-width: 100%;
}

/* single choice with image */
.sc_simple.added_picture .added_content img {
    /* display: block;
    margin: 0 auto; */
    max-height: 200px;
}

/* single choice with audio */
.sc_simple.added_picture .added_content img {
    /* display: block;
    margin: 0 auto; */
    max-height: 200px;
}


#countDown{
   text-align: center;
   color: red;
   font-size: x-large;
}

.divHover {
  text-decoration: underline;
  margin-bottom: 16px;
  cursor: pointer;
}

main .answers .singleanswer {
  clear: both;
}

.maincontent .answers .singleanswer label p {
    display: inline;
}

.answers .singleanswer label p {
    display: inline;
}

@media (max-width: 760px) {
    /* single choice horizontal */
    .sc_weighting .answers {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .sc_weighting .answers .singleanswer {
        float: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .sc_weighting .answers .singleanswer input[type="radio"] {
        float: none;
        order: 2;
        margin: 0 auto;
    }
    .sc_weighting .answers .singleanswer label {
        float: none;
        order: 1;
        white-space: nowrap;
    }
    .sc_weighting .answers .singleanswer {
        text-align: center;
        width: 15%;
    }
    .sc_weighting .answers .singleanswer .accesskey {
        float: none;
        display: block;
        margin: 0;
    }
    .sc_weighting .helperdiv {
        display: block;
        height: 0.5em;
        background: #eee;
        border-radius: 1em;
        margin-top: -1em;
        -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    }


    html, body, #root, .App {
        min-height: 100%;
        background-color: #fff;
    }

    #contentwrapper {
        max-width: 100%;
        margin: auto;
        padding: unset;
        background: #fff;
        min-height: 100%;
    }

    footer {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
    }

    .cntbrand {
        margin: auto;
        display: contents;
        width: 100%;
    }
    .logomandant {
        order: unset;
        width: auto;
    }
 }
